import React, { useEffect, useState } from "react";
import {useRef} from "react";
import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
} from "@/components/organisms/forms/select";
import { Label } from "@/components/atoms/texts/label";
import { Input } from "@/components/ui/input";
import { useFormEditBills } from "./useFormEditBills";
import FormIsSend from "@/components/organisms/feedback/formIsSend";
import PrintErrorMessage from "@/components/organisms/feedback/PrintErrorMessage";
import useConsultant from "@/components/organisms/navigation/MenuConsultant/useConsultant";
import { useConsultantContext } from "@/context/ConsultantContext";
import useAllCurrencies from "@/services/global-services/query/useAllCurrencies";
import getCurrencySymbolFromCode from "@/utils/getCurrencySymbolFromCode";
import { useDeleteConsultantInvoice } from "@/services/admin-services/mutations/useDeleteConsultantInvoice";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@/components/molecules/buttons/button";
import { Separator } from "@/components/atoms/divider/separatorShadcn";
import { useToast } from "@/components/organisms/feedback/use-toast";
import { Switch } from "@/components/ui/switch";

export default function FormEditBills({ global = false, submitRef, onSubmit, editData, submissionSuccess, submissionError, setChangeContent }) {

    const { activeUser } = useConsultantContext();
    const { usersFormated } = useConsultant();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const { toast } = useToast();
    const scrollContainerRef = useRef(null);


    const nameInUserFormated = activeUser && activeUser.uuid;

    const { loading: loadingCurrencies, error: errorCurrencies, currencies } = useAllCurrencies();
    const { deleteConsultantInvoice, loading: loadingDelete, error: errorDelete, data: dataDelete } = useDeleteConsultantInvoice();

    const {
        consultantUuid,
        customer,
        billNumber,
        days,
        amountClient,
        amountConsultant,
        currency,
        tJMClient,
        tJMConsultant,
        periodStart,
        periodEnd,
        invoiceDate,
        commission,
        consultantUuidError,
        customerError,
        billNumberError,
        daysError,
        amountClientError,
        amountConsultantError,
        currencyError,
        tJMClientError,
        tJMConsultantError,
        periodStartError,
        periodEndError,
        invoiceDateError,
        commissionError,
        formIsValid,
        setConsultantUuid,
        setCustomer,
        setBillNumber,
        setDays,
        setAmountClient,
        setAmountConsultant,
        setCurrency,
        setTJMClient,
        setTJMConsultant,
        setPeriodStart,
        setPeriodEnd,
        setInvoiceDate,
        setCommission,
        manualCommission,
        setManualCommission,
        validateForm,
        resetForm,
    } = useFormEditBills(editData, nameInUserFormated);
    const symbol = getCurrencySymbolFromCode(currency)

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm("all")) {
            onSubmit({
                consultantUuid,
                customer,
                billNumber,
                days,
                amountClient,
                amountConsultant,
                currency,
                tJMClient,
                tJMConsultant,
                periodStart,
                periodEnd,
                invoiceDate,
                commission, 
                manualCommission
            });
        }
        scrollToTop();
    };

    const [allCurrencies, setAllCurrencies] = useState();

    useEffect(() => {
        currencies && setAllCurrencies(currencies);
    }, [currencies]);

    // useEffect(() => {
    //     if (submissionSuccess) {
    //         resetForm();
    //     }
    // }, [submissionSuccess]);

    const handleDeleteInvoice = (invoiceUuid) => {
        // console.log("invoiceUuid = ", invoiceUuid);
        deleteConsultantInvoice(invoiceUuid);

        setConfirmDelete(false);
    }
    useEffect(() => {
        // console.log("dataDelete", dataDelete)
        if (dataDelete) {
            setChangeContent();

            // refetchFromScratch(1)
        }
    }, [dataDelete])

    const scrollToTop = () => {
        // importer le hook useRef : import {useRef} from "react";
        // Déclarer la ref :     const scrollContainerRef = useRef(null);
        // ajouter au contenant : className="h-full overflow-auto" ref={scrollContainerRef} 
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = 0; // Définit la position de défilement à 0 pour remonter en haut
        }
      };
      
    return (
        <div className="w-full h-full overflow-auto" ref={scrollContainerRef} >
            {submissionSuccess && !submissionError && <FormIsSend message="success.changesSaved" />}
            {submissionError && <PrintErrorMessage error={submissionError} />}

            <form onSubmit={handleSubmit} className="flex flex-col gap-4 p-4 h-full " >
                {/* Consultant */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.companyBenefits.consultant" />
                    <Select value={consultantUuid} onValueChange={(value) => setConsultantUuid(value)}>
                        <SelectTrigger
                            className={`col-span-3 p-2 border rounded-md text-body bg-white ${consultantUuidError === true && `border-error`
                                } ${consultantUuidError === false && `border-success`} ${consultantUuidError == null && `border-greyStrong`
                                }`}
                        >
                            <SelectValue placeholder={<Label message="global.actions.select" />} />
                        </SelectTrigger>
                        <SelectContent>
                            <>
                                {global
                                    ? usersFormated && usersFormated.map((user) => (
                                        <SelectItem value={user.uuid}>{user.trigram + " " + user.firstname + " " + user.lastname}</SelectItem>
                                    ))
                                    : activeUser &&
                                    <SelectItem value={activeUser.uuid}>{activeUser.trigram + " " + activeUser.firstname + " " + activeUser.lastname}</SelectItem>

                                }
                            </>
                        </SelectContent>
                    </Select>
                </div>

                {/* Customer */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.billsDetails.customer" />
                    <Input
                        value={customer}
                        onChange={(e) => setCustomer(e.target.value)}
                        onBlur={() => validateForm("customerErrorTest")}
                        className={`col-span-3 p-2 border rounded-md  ${customerError === true && `border-error`
                            } ${customerError === false && `border-success`} ${customerError == null && `border-greyStrong`
                            }`
                        }
                    />
                    {customerError && <Label message="error.mandatory" color="error" />}
                </div>

                {/* Bill Number */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.billsDetails.billNumber" />
                    <Input
                        value={billNumber}
                        onChange={(e) => setBillNumber(e.target.value)}
                        onBlur={() => validateForm("billNumberErrorTest")}
                        className={`col-span-3 p-2 border rounded-md ${billNumberError === true && `border-error`
                            } ${billNumberError === false && `border-success`} ${billNumberError == null && `border-greyStrong`
                            }`
                        }
                    />
                    {billNumberError && <Label message="error.mandatory" color="error" />}
                </div>

                {/* Invoice Date */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.billsDetails.invoiceDate" />
                    <Input
                        type="date"
                        value={invoiceDate}
                        onChange={(e) => setInvoiceDate(e.target.value)}
                        onBlur={() => validateForm("invoiceDateErrorTest")}
                        className={`col-span-3 p-2 border rounded-md ${invoiceDateError === true && `border-error`
                            } ${invoiceDateError === false && `border-success`} ${invoiceDateError == null && `border-greyStrong`
                            }`}
                    />
                    {invoiceDateError && <Label message="error.mandatory" color="error" />}
                </div>

                {/* Period Start */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.prospectConsultantPanel.formPlaceHolder.startDate" />
                    <Input
                        type="date"
                        value={periodStart}
                        onChange={(e) => setPeriodStart(e.target.value)}
                        onBlur={() => validateForm("periodStartErrorTest")}
                        className={`col-span-3 p-2 border rounded-md ${periodStartError === true && `border-error`
                            } ${periodStartError === false && `border-success`} ${periodStartError == null && `border-greyStrong`
                            }`}
                    />
                    {periodStartError && <Label message="error.mandatory" color="error" />}
                </div>

                {/* Period End */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.prospectConsultantPanel.formPlaceHolder.endDate" />
                    <Input
                        type="date"
                        value={periodEnd}
                        onChange={(e) => setPeriodEnd(e.target.value)}
                        onBlur={() => validateForm("periodEndErrorTest")}
                        className={`col-span-3 p-2 border rounded-md ${periodEndError === true && `border-error`
                            } ${periodEndError === false && `border-success`} ${periodEndError == null && `border-greyStrong`
                            }`}
                    />
                    {periodEndError && <Label message="error.mandatory" color="error" />}
                </div>

                {/* Days */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.billsDetails.days" />
                    <Input
                        type="number"
                        value={days}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => setDays(e.target.value)}
                        onBlur={() => validateForm("daysErrorTest")}
                        className={`col-span-3 p-2 border rounded-md ${daysError === true && `border-error`
                            } ${daysError === false && `border-success`} ${daysError == null && `border-greyStrong`
                            }`}
                    />
                    {daysError && <Label message="error.mandatory" color="error" />}
                </div>

                {/* Currencies */}
                <div className='grid grid-cols-4 items-center gap-secondDegree w-full'>
                    <Label fontWeight="bold" className="col-span-1" message="consultants.expense.currency" />
                    {errorCurrencies ? <PrintErrorMessage error={errorCurrencies} className={'col-span-3'} /> :
                        <Select className="" value={currency} onValueChange={(e) => { setCurrency(e) }} onBlur={() => validateForm("currencyErrorTest")}                       >
                            <SelectTrigger className={`col-span-3 p-2 bg-white border border-greyStrong text-body `}>
                                <SelectValue placeholder="Currency" />
                            </SelectTrigger>
                            <SelectContent >
                                {loadingCurrencies ? <span className='loader' /> : (allCurrencies?.map((cur, index) => (
                                    <SelectItem key={index} value={cur.code}>
                                        <Label >{cur.code + " " + cur.symbol}</Label>
                                    </SelectItem>
                                )))}
                            </SelectContent>
                        </Select>}
                </div>

                {/* Daily Rate Client */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.overview.dailyRateClient" />
                    <Input
                        type="number"
                        value={tJMClient}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => setTJMClient(e.target.value)}
                        onBlur={() => validateForm("tJMClientErrorTest")}
                        className={`col-span-3 p-2 border rounded-md 
                            ${tJMClientError === true && `border-error`
                            } ${tJMClientError === false && `border-success`} ${tJMClientError == null && `border-greyStrong`
                            }`}
                    />
                    {tJMClientError && <Label message="error.mandatory" color="error" />}
                </div>

                {/* Daily Rate Consultant */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.overview.dailyRateConsultant" />
                    <Input
                        type="number"
                        value={tJMConsultant}
                        onChange={(e) => setTJMConsultant(e.target.value)}
                        onBlur={() => validateForm("tJMConsultantErrorTest")}
                        onWheel={(e) => e.target.blur()}
                        className={`col-span-3 p-2 border rounded-md ${tJMConsultantError === true && `border-error`
                            } ${tJMConsultantError === false && `border-success`} ${tJMConsultantError == null && `border-greyStrong`
                            }`}
                    />
                    {tJMConsultantError && <Label message="error.mandatory" color="error" />}
                </div>

                {/* Commission Is Auto */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.billsDetails.manualCommission" />
                    <Switch
                        checked={manualCommission}
                        onCheckedChange={(value) => setManualCommission(value)}
                        className="col-span-3"
                    />
                </div>
                {/* Commission */}
                {manualCommission && (
                    <div className="grid grid-cols-4 items-center gap-2 w-full">
                        <Label fontWeight="bold" className="col-span-1" message="consultants.statement.billsDetails.commission" />
                        <Input
                            type="number"
                            value={commission}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => setCommission(e.target.value)}
                            onBlur={() => validateForm("commissionErrorTest")}
                            className={`col-span-3 p-2 border rounded-md ${commissionError === true && `border-error`
                            } ${commissionError === false && `border-success`} ${commissionError == null && `border-greyStrong`
                            }`}
                        />
                    </div>
                )}
                {/* //TODO : La commission manuelle affete toute les factures du mois */}

                {/* Amount Client */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.billsDetails.amountCustomer" />
                    <Input
                        type="number"
                        disabled
                        onWheel={(e) => e.target.blur()}
                        value={amountClient}
                        // onChange={(e) => setAmountClient(e.target.value)}
                        className={`col-span-3 p-2 border rounded-md ${amountClientError && "border-error"
                            }`}
                    />
                </div>

                {/* Amount Consultant */}
                <div className="grid grid-cols-4 items-center gap-2 w-full">
                    <Label fontWeight="bold" className="col-span-1" message="consultants.statement.billsDetails.amountConsultant" />
                    <Input
                        type="number"
                        value={amountConsultant}
                        onWheel={(e) => e.target.blur()}
                        disabled
                        // onChange={(e) => setAmountConsultant(e.target.value)}
                        className={`col-span-3 p-2 border rounded-md ${amountConsultantError && "border-error"
                            }`}
                    />
                </div>




                {errorDelete}
                {errorDelete && <PrintErrorMessage error={errorDelete} />}
                <Separator />
                {confirmDelete ? <><Label message="global.actions.askConfirmDelete" /> <Button text="global.actions.confirmDelete" onClick={() => handleDeleteInvoice(editData.uuid)} className="text-white bg-error font-bold hover:bg-destructive " /></> : <Button style="outline" text="global.actions.delete" onClick={() => setConfirmDelete(true)} className="text-error border border-error font-bold hover:bg-error20" showLeftIcon leftIcon={<DeleteIcon />}></Button>
                }



                {/* <div className="flex justify-end">
          <button type="submit" className="btn-primary">
            <Label message="global.actions.submit" />
          </button>
        </div> */}
                <Input type='submit' className='hidden' ref={submitRef} />

            </form>
        </div>
    );
}
